<template>
  <div v-if="items.length > 0">
    <v-row
      class="my-15"
      :class="commande.type_cmd != 1 && commande.statut == 'devis' ? 'first-col' : ''"
    >
      <v-col
        id="choisir_piscinelle"
        class="scrollme"
        cols="12"
      >
        <p class="text-h4">
          {{ $t('choisir-piscinelle.title') }}
        </p>
        <p class="muted--text">
          {{ $t('choisir_piscinelle_desc') }}
        </p>
        <div>
          <v-list class="pa-0 d-flex flex-wrap">
            <template
              v-for="(contenu, index) in items"
            >
              <v-list-item
                :key="`list-${index}`"
                class="choisir-piscinelle mx-auto px-0 flex-column justify-center"
                :class="!$vuetify.breakpoint.xs ? 'two-per-row' : ''"
              >
                <v-card
                  class="mx-auto"
                  max-width="300"
                  height="480"
                >
                  <img
                    :src="contenu.img ? contenu.img : 'https://www.piscinelle.com/upload/renovation-piscine-grande-maison-couverture-mobile-terrasse-belgique-201909-03-M.jpg'"
                    height="auto"
                    width="100%"
                  >

                  <v-card-title class="title-choisir-piscinelle mb-2">
                    {{ $t(contenu.keyTrad) }}
                  </v-card-title>

                  <v-card-subtitle class="muted--text pb-0 mb-2">
                    {{ $t(contenu.description) }}
                  </v-card-subtitle>

                  <v-card-actions>
                    <v-btn
                      text
                      plain
                      class="text-body-2 cursor-pointer choisir-piscinelle-link black--text"
                      @click.prevent="currentItem = index"
                    >
                      {{ $t('savoir-plus') }}<v-icon
                        color="black"
                        dense
                      >
                        mdi-chevron-right
                      </v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-list-item>
            </template>
          </v-list>
        </div>
        <DialogCarousel
          v-model="currentItem"
          :items="items"
        >
          <template v-slot:default="{ item }">
            <v-card
              class="contenu-piscinelle overflow-y-hidden"
            >
              <v-card-title class="pa-3 dialog-card-color white--text flex-nowrap align-center">
                {{ $t(item.keyTrad) }}
                <a
                  v-if="isAdmin() && item.id"
                  class="white--text text-decoration-underline text-body-2 ml-5"
                  target="_blank"
                  :href="piscinelleUrl + 'admin/contenu-edition/'+item.id"
                >Modifier le descriptif</a>
                <v-spacer />
                <v-btn
                  icon
                  dark
                  @click="currentItem = false"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-card-title>
              <v-card-text
                class="pa-0 pb-15 fill-height overflow-y-hidden"
              >
                <iframe
                  width="100%"
                  height="100%"
                  :src="piscinelleUrl + ($i18n.locale == 'fr' ? '' : $i18n.locale+'/') + 'utils/contenu-iframe?co='+item.id"
                />
              </v-card-text>
            </v-card>
          </template>
        </DialogCarousel>
      </v-col>
    </v-row>
    <v-divider
      class="big-divider"
    />
  </div>
</template>

<script>
import DialogCarousel from '@/components/DialogCarousel'

export default {
  name: 'ChoisirPiscinelle',
  components: {
    DialogCarousel
  },
  props: {
    commande: {
      type: Object,
      default: () => ({})
    }
  },
  data () {
    return {
      currentItem: false,
      piscinelleUrl: process.env.VUE_APP_PISCINELLE_URL,
      petiteSourceUrl: process.env.VUE_APP_PETITE_SOURCE_URL,
      url: process.env.BASE_URL
    }
  },
  computed: {
    items () {
      var items = []
      if (!this.commande.is_petite_source_internaute && (this.commande.is_configInternaute || !this.commande.publie)) {
        items = [
          { keyTrad: 'choisir-piscinelle.groupe-piscinelle.title', description: 'choisir-piscinelle.groupe-piscinelle.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_GROUPE_PISCINELLE.jpg', id: 207 },
          { keyTrad: 'choisir-piscinelle.design-reconnu.title', description: 'choisir-piscinelle.design-reconnu.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_DESIGN_RECONNU.png', id: 197 },
          { keyTrad: 'choisir-piscinelle.consommation-locale.title', description: 'choisir-piscinelle.consommation-locale.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_CONSO_LOCALE.png', id: 2306 },
          { keyTrad: 'choisir-piscinelle.consommation-energetique.title', description: 'choisir-piscinelle.consommation-energetique.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_CONSOMMATION.png', id: 2308 },
          { keyTrad: 'choisir-piscinelle.bilan-impact.title', description: 'choisir-piscinelle.bilan-impact.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_BILAN_IMPACT.jpg', id: 2515 },
          { keyTrad: 'choisir-piscinelle.fpp.title', description: 'choisir-piscinelle.fpp.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_FPP.png', id: 219 },
          { keyTrad: 'choisir-piscinelle.kit-ecole.title', description: 'choisir-piscinelle.kit-ecole.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_ECOLE_PISCINELLE.png', id: 49 },
          { keyTrad: 'choisir-piscinelle.declaration-travaux.title', description: 'choisir-piscinelle.declaration-travaux.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_DECLARATION_TRAVAUX.jpg', id: 11 },
          { keyTrad: 'choisir-piscinelle.garantie-piscine.title', description: 'choisir-piscinelle.garantie-piscine.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_GARANTIE.png', id: 203 },
          { keyTrad: 'choisir-piscinelle.avis-clients.title', description: 'choisir-piscinelle.avis-clients.description', img: this.url + 'img/SECTION_CHOISIR_PISCINELLE_ESPACE_CLIENT_AVIS_CLIENT.png', id: 2122 }
        ]
      } else {
        const annexes = this.commande.custom_field_array.annexes
        for (const index in annexes) {
          if (typeof annexes[index].checked !== 'undefined') {
            items.push({ keyTrad: 'choisir-piscinelle.' + `${index}` + '.title', description: 'choisir-piscinelle.' + `${index}` + '.description', img: this.url + 'img/' + `${annexes[index].img}`, id: `${annexes[index].id}` })
          }
        }
      }

      return items
    }
  }
}
</script>
