<template>
  <v-dialog
    v-model="dialogOpened"
    transition="dialog-bottom-transition"
    scrollable
    content-class="dialog-contenu hide-scroll-while-open force-100-height"
    max-width="700px"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <v-carousel
      :id="packingList ? 'packing-list' : ''"
      v-model="carouselSlide"
      hide-delimiters
      height="100%"
      class="slider-contenu"
      :continuous="false"
      progress
      @change="handleChangeCarousel"
    >
      <template v-slot:prev="{on, attrs}">
        <v-btn
          v-bind="attrs"
          text
          v-on="on"
        >
          <v-icon
            left
            large
          >
            mdi-chevron-left
          </v-icon>
          {{ $t('precedent') }}
        </v-btn>
      </template>
      <template v-slot:next="{on, attrs}">
        <v-btn
          v-bind="attrs"
          text
          v-on="on"
        >
          {{ $t('suivant') }}
          <v-icon
            right
            large
          >
            mdi-chevron-right
          </v-icon>
        </v-btn>
      </template>
      <v-carousel-item
        v-for="(item, index) in items"
        :key="index"
      >
        <slot :item="item" />
      </v-carousel-item>
    </v-carousel>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogCarousel',
  props: {
    items: {
      type: [Array, Object],
      default: () => []
    },
    value: {
      type: [Number, Boolean],
      default: false
    },
    packingList: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      carouselSlide: 0
    }
  },
  computed: {
    dialogOpened: {
      get () {
        this.setSlide(this.value)
        return this.value !== false
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  updated () {
    this.hideScrollBarDialogOpen()
  },
  methods: {
    handleChangeCarousel (slideIndex) {
      this.$emit('input', slideIndex)
    },
    setSlide (index) {
      if (Number.isInteger(index)) { this.carouselSlide = index }
    },
    hideScrollBarDialogOpen () {
      if (document.querySelector('.v-dialog__content--active .hide-scroll-while-open')) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'scroll'
      }
    }
  }
}
</script>
<style lang="scss">
.dialog-contenu{
  height: 100%;

  .v-carousel.slider-contenu{
    height: inherit !important;

    .v-window__prev, .v-window__next{
      top: unset;
      bottom: 9px;
      border-radius: 2px;
      z-index:10;
    }

    .v-card{
      height: 100%;
      overflow-y: auto;
    }
  }
}
</style>
