<template>
  <v-card
    flat
  >
    <v-card-text>
      <v-row>
        <template
          v-for="(item, index) in items"
        >
          <v-col
            v-if="(item.data && item.data != false) || item.img"
            :key="index"
            cols="12"
            sm="6"
            class="pa-0"
          >
            <v-list class="pa-0">
              <v-list-item
                class="pa-0"
              >
                <v-list-item-content
                  class="pa-0"
                >
                  <v-list-item-title class="text-wrap">
                    {{ $t(index) }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <span
                      v-if="item.data"
                      :inner-html.prop="item.data | capitalize({ onlyFirstLetter: true }) "
                    />

                    <v-avatar
                      v-if="item.img"
                      size="100"
                    >
                      <v-img :src="item.img" />
                    </v-avatar>

                    <v-tooltip
                      v-if="item.tooltip"
                      content-class="custom-tooltip"
                      bottom
                      transition="scroll-y-transition"
                      open-on-click
                      z-index="9999"
                      max-width="500px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <span
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon
                            class="grey white--text pa-1 ms-2 rounded-circle"
                            x-small
                          >mdi-help</v-icon>
                        </span>
                      </template>
                      <!-- eslint-disable vue/no-v-html -->
                      <span
                        v-html="item.tooltip"
                      />
                      <br v-if="item.tooltip2">
                      <span
                        v-if="item.tooltip2"
                        v-html="item.tooltip2"
                      />
                      <!-- eslint-enable vue/no-v-html -->
                    </v-tooltip>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </template>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InfosListe',
  props: {
    items: {
      type: Object,
      required: true
    }
  }
}
</script>
