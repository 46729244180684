<template>
  <div id="chatbot-container" />
</template>

<script>
export default {
  name: 'Chatbot',
  mounted () {
    // Ajouter le script de configuration pour le chatbot
    window.embeddedChatbotConfig = {
      chatbotId: 'LTntH6uxgBdc8jhi9wEXo',
      domain: 'www.chatbase.co'
    }

    // Ajouter le script de chatbot
    const script = document.createElement('script')
    script.src = 'https://www.chatbase.co/embed.min.js'
    script.setAttribute('chatbotId', 'LTntH6uxgBdc8jhi9wEXo')
    script.setAttribute('domain', 'www.chatbase.co')
    script.defer = true
    document.body.appendChild(script)
  }
}
</script>
